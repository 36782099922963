import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { showToast } from '@/app/toasts/utils/showToast';
import { updateLanguage } from '@/app/user/models/user';
import { useAppDispatch } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import LanguageSwitch from '@/ui/components/LanguageSwitch';
import { saveCookie } from '@/utils/cookies';

import { NAME } from '../../../constants';

import type { Language } from 'types/generic';

interface Props {
    isWorkspaceUser: boolean;
}

const Footer = ({ isWorkspaceUser }: Props) => {
    const { t } = useTranslation(NAME);
    const router = useRouter();
    const dispatch = useAppDispatch();

    const { locale } = router;

    const handleLanguageSwitch = async (newLanguage: string) => {
        const { pathname, query, asPath } = router;

        const expires = 365;

        saveCookie('NEXT_LOCALE', newLanguage, expires);
        await router.push({ pathname, query }, asPath, { locale: newLanguage, scroll: false });

        // also update user language in DB
        dispatch(updateLanguage(newLanguage as Language));

        if (window.Intercom && typeof window.Intercom === 'function') {
            window.Intercom('update', { language_override: newLanguage });
        }

        showToast({ type: 'success', message: t('language-changed') });

        track(TRACKING_EVENTS.navigation.languageSwitch.clicked, { language: newLanguage });
    };

    const defaultFooter = (
        <div className="mt-2 flex items-center justify-between border-t p-2">
            <DropdownMenu.Item asChild>
                <Link
                    href="/logout"
                    className="flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium text-red-500 outline-none hover:bg-red-50 hover:text-red-600"
                >
                    <ArrowRightStartOnRectangleIcon className="size-4" />
                    {t('logout')}
                </Link>
            </DropdownMenu.Item>
            <LanguageSwitch language={locale as Language} onLanguageSwitch={handleLanguageSwitch} />
        </div>
    );

    const workspaceUserFooter = (
        <div className="flex items-center justify-between p-3">
            <LanguageSwitch
                wide
                language={locale as Language}
                onLanguageSwitch={handleLanguageSwitch}
            />
        </div>
    );

    return isWorkspaceUser ? workspaceUserFooter : defaultFooter;
};

export default Footer;
